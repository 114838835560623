<template>
    <v-tab @change="$emit('changed')">
        <div style="width:100%" class="text-right" v-show="$root.windowWidth >= 600">
            {{$t(navpoint.text)}}
        </div>
        <v-icon right>
            {{navpoint.icon}}
        </v-icon>
    </v-tab>
</template>

<script>
    export default {
        props: ['navpoint']
    }
</script>